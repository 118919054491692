import './App.css'
import { Welcome } from './components'
import React from 'react'

function App(): React.ReactElement {
  return (
    <div className="app">
      <Welcome />
    </div>
  )
}

export default App
