import logo from '../../logo.svg'
import styles from './Welcome.module.css'
import React from 'react'

export default function Welcome(): React.ReactElement {
  return (
    <header className={styles.appHeader}>
      <img src={logo} className={styles.appLogo} alt="logo" />
      <h1>Welcome</h1>
      <p>
        Edit <code>src/App.tsx</code> and save to reload.
      </p>
      <a className={styles.appLink} href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
        Learn React
      </a>
    </header>
  )
}
